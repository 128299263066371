<template>
  <b-card>
    <form-component :job-offer="jobOffer" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/job-offers/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/job-offers';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      jobOffer: {
        title: null,
        title_image: null,
        description: '',
        requirements: [],
        benefits: [],
        nice_to_have: [],
        responsibilities: [],
        rate: '',
        working_time: '',
        site_images: [null, null],
        city: '',
        tag: '',
      },
    };
  },

  methods: {
    async onSubmit(form) {
      const formData = new FormData();

      formData.append('title', form.title);
      formData.append('lang', form.lang);
      formData.append('description', form.description);
      formData.append('rate', form.rate);
      formData.append('city', form.city);
      formData.append('working_time', form.working_time);
      formData.append('seo_description', form.seo_description);
      formData.append('tag', form.tag);

      formData.append('responsibilities', JSON.stringify(form.responsibilities.map((item) => item.value)));
      formData.append('benefits', JSON.stringify(form.benefits.map((item) => item.value)));
      formData.append('requirements', JSON.stringify(form.requirements.map((item) => item.value)));
      formData.append('nice_to_have', JSON.stringify(form.nice_to_have.map((item) => item.value)));

      if (form.site_images[0]) {
        formData.append('site_images[0]', form.site_images[0].file, form.site_images[0].file.fileName);
      }

      if (form.site_images[1]) {
        formData.append('site_images[1]', form.site_images[1].file, form.site_images[1].file.fileName);
      }

      try {
        await create(formData);

        this.showSuccessNotification('Dane zapisane', 'Oferta została dodana.');
        this.$router.push({ name: 'job-offers-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania oferty pracy. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
